import {Children} from 'react'
import {GridAutoFlowProps, GridGapProps, MarginProps} from 'styled-system'
import Circle1SVG from 'assets/svgs/circle1.svg'
import Circle2SVG from 'assets/svgs/circle2.svg'
import Circle3SVG from 'assets/svgs/circle3.svg'
import styled from '@emotion/styled'

import * as styles from 'src/styles'
import {Grid} from 'src/components/Box'

const LIST_COUNTER = 'ol-list-counter' as const

interface Props extends MarginProps {
  flowDirection?: GridAutoFlowProps['gridAutoFlow']
  gap?: GridGapProps['gridGap']
  children?: React.ReactNode | React.ReactNode[]
}

const OrderedListItem = styled.li({
  counterIncrement: LIST_COUNTER,
  display: 'flex',
  position: 'relative',
  marginLeft: `calc(2.5 * ${styles.typography.lede.fontSize})`,
  '&:before': {
    ...styles.typography.lede,
    left: `calc(-2.5 * ${styles.typography.lede.fontSize})`,
    top: `calc(-0.8 * ${styles.typography.lede.fontSize})`,
    position: 'absolute',
    content: `counter(${LIST_COUNTER})`,
    textAlign: 'center',
    padding: `calc(0.75 * ${styles.typography.lede.fontSize})`,
  },
})

const listCircle = (url: string) => ({
  backgroundImage: `url(${url})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
})

const orderedListCss = {
  listStyle: 'none',
  counterReset: LIST_COUNTER,
  'li:nth-of-type(3n+1):before': listCircle(Circle1SVG),
  'li:nth-of-type(3n+2):before': listCircle(Circle2SVG),
  'li:nth-of-type(3n+3):before': listCircle(Circle3SVG),
}

export const OrderedList = ({
  children,
  flowDirection,
  gap = '3',
  ...props
}: Props) => (
  <Grid
    css={orderedListCss}
    as="ol"
    gridAutoFlow={flowDirection ?? 'row'}
    gridGap={gap}
    {...props}
  >
    {Children.toArray(children).map((c, i) => (
      <OrderedListItem key={i}>{c}</OrderedListItem>
    ))}
  </Grid>
)

const UnorderedListItem = styled.li({
  listStyleType: 'disc',
})

export const UnorderedList = ({
  children,
  flowDirection,
  gap = '1',
  ...props
}: Props) => (
  <Grid as="ul" gridAutoFlow={flowDirection ?? 'row'} gridGap={gap} {...props}>
    {Children.toArray(children).map((c, i) => (
      <UnorderedListItem key={i}>{c}</UnorderedListItem>
    ))}
  </Grid>
)
