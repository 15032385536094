import React, {useState} from 'react'
import {variant} from 'styled-system'
import styled from '@emotion/styled'

import {Header2, Header3} from 'src/components/text'
import {GreenYellowUnderlineV2} from 'src/components/emphasis'
import SearchResultCard from 'src/components/ScholarshipCard/SearchResultCard'
import {Box, Flex} from 'src/components/Box'
import {buttonBaseCss, ButtonVariant, buttonVariants} from 'src/styles/button'
import {SearchResultType} from 'src/pages/scholarships'
interface Props {
  variant?: ButtonVariant
}

export const ExternalButtonLink = styled.a<Props>(
  buttonBaseCss,
  variant({
    variants: buttonVariants,
  })
)

type SearchResultsProps = {
  searchResults: SearchResultType[]
}

const ScholarshipResults = ({searchResults}: SearchResultsProps) => {
  const [visibleResults, setVisibleResults] = useState(5)

  const loadMore = () => {
    setVisibleResults((prev) => prev + 5)
  }

  if (searchResults.length === 0) {
    return (
      <Box my="6">
        <Header3 as="p">No results found.</Header3>
      </Box>
    )
  }

  return (
    <div>
      <Header2 mt={5}>
        <GreenYellowUnderlineV2>
          Scholarship Search Results
        </GreenYellowUnderlineV2>
      </Header2>
      <Flex
        mt={5}
        flexWrap="wrap"
        gridRowGap={5}
        gridColumnGap={2}
        justifyContent="space-evenly"
      >
        {searchResults.slice(0, visibleResults).map((item, index) => (
          <SearchResultCard
            key={index}
            title={item.title}
            description={item.description}
            requirements={item.requirements}
            source={item.source}
            price={item.price}
            date={item.date}
            count={item.count}
            applyLink={item.applyLink}
            featuredImageUrl={item.featuredImageUrl}
          />
        ))}
      </Flex>
      {visibleResults < searchResults.length && (
        <div style={{textAlign: 'center', marginTop: '10px'}}>
          <ExternalButtonLink
            onClick={loadMore}
            variant="primary"
            css={{
              fontWeight: 700,
              width: '100%',
              maxWidth: '10rem',
              marginTop: 50,
            }}
          >
            Load more
          </ExternalButtonLink>
        </div>
      )}
    </div>
  )
}

export default ScholarshipResults
