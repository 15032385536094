import React from 'react'
import styled from '@emotion/styled'
import {space, color} from 'styled-system'

import {Flex, Grid} from 'src/components/Box'
import {ExtraSmallBody, Header4} from 'src/components/text'
import {theme} from 'src/styles'
import {
  BoxIcon,
  ClockIcon,
  DollarIcon,
  // HeartFilledIcon,
  // HeartHollowIcon,
} from 'src/components/icons'
import {commaSeparateNumber, truncateString} from 'src/lib/util'

import {BlueUnderlineRounded} from '../emphasis'

const CardContainer = styled.div`
  height: auto;
  background-color: #ffffff;
  border-radius: 4px;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0px 4px 20px 0px #14242c40;
  padding: ${theme.space[2]};
  position: relative;

  ${space}
  ${color}
`

type ScholarshipCardProps = {
  title: string
  source: string
  price: number
  date: string
  applyLink?: string
  isLiked: boolean
  isDisabled: boolean
  featuredImageUrl: string
}

const MobileCard = ({
  title,
  price,
  source,
  date,
  applyLink = '#',
  featuredImageUrl,
}: ScholarshipCardProps) => {
  const backgroundImage = featuredImageUrl
    ? {
        backgroundRepeat: 'repeat',
        backgroundImage: `url("${featuredImageUrl}")`,
        height: 100,
        backgroundSize: 'contain',
      }
    : {}
  return (
    <>
      {featuredImageUrl && (
        <a href={applyLink} target="_blank" rel="noreferrer">
          <CardContainer
            css={{
              width: 202,
              minWidth: 202,
              height: 122,
              minHeight: 122,
              ...backgroundImage,
            }}
          ></CardContainer>
        </a>
      )}
      {!featuredImageUrl && (
        <CardContainer
          css={{width: 202, minWidth: 202, height: 122, minHeight: 122}}
        >
          <Header4 textAlign="center" fontSize={15} mb={1}>
            {truncateString(title, 50, '')}
          </Header4>
          <Flex alignItems="center" justifyContent="space-between">
            <Flex flexDirection="column">
              <Grid
                gridRowGap="1"
                gridTemplateColumns={{_: 'auto auto'}}
                gridTemplateRows={{_: 'auto auto'}}
              >
                <>
                  <DollarIcon height={10} mt="1px" />
                  {price && (
                    <ExtraSmallBody fontSize={8}>
                      ${commaSeparateNumber(price)}
                    </ExtraSmallBody>
                  )}
                  {!price && (
                    <ExtraSmallBody fontSize={8}>See website</ExtraSmallBody>
                  )}
                </>
                <>
                  <ClockIcon height={10} mt="1px" />
                  {date && <ExtraSmallBody fontSize={8}>{date}</ExtraSmallBody>}
                  {!date && (
                    <ExtraSmallBody fontSize={8}>See website</ExtraSmallBody>
                  )}
                </>
                <>
                  <BoxIcon height={10} mt="1px" />
                  {source && (
                    <ExtraSmallBody fontSize={8}>
                      {truncateString(source, 55, '')}
                    </ExtraSmallBody>
                  )}
                  {!source && (
                    <ExtraSmallBody fontSize={8}>See website</ExtraSmallBody>
                  )}
                </>
              </Grid>
            </Flex>
            <a
              target="_blank"
              href={applyLink}
              css={{
                fontWeight: 600,
                width: '100%',
                maxWidth: 'fit-content',
                textDecoration: 'none',
                color: '#000000',
                fontSize: '12px',
                marginBottom: 10,
              }}
              rel="noreferrer"
            >
              <BlueUnderlineRounded
                css={{
                  borderBottom: '6px solid',
                  borderImageWidth: '0 0 8px 0',
                }}
              >
                Apply Now
              </BlueUnderlineRounded>
            </a>
          </Flex>
        </CardContainer>
      )}
    </>
  )
}

export default MobileCard
