import {variant} from 'styled-system'
import {Link} from 'gatsby'
import styled from '@emotion/styled'

import {buttonBaseCss, ButtonVariant, buttonVariants} from 'src/styles/button'

interface Props {
  variant?: ButtonVariant
}

export const ExternalButtonLink = styled.a<Props>(
  buttonBaseCss,
  variant({
    variants: buttonVariants,
  })
)

ExternalButtonLink.defaultProps = {
  variant: 'primary',
}

const ButtonLink = ExternalButtonLink.withComponent(Link)

ButtonLink.defaultProps = {
  variant: 'primary',
}

export default ButtonLink
