import {InputHTMLAttributes, useRef} from 'react'
import {Field, Form} from 'react-final-form'

import {Action} from 'src/components/Action'
import {Box, Flex} from 'src/components/Box'
import * as styles from 'src/styles'
import {SearchIcon} from 'src/components/icons'
import {SmallBody} from 'src/components/text'
import {gradients} from 'src/styles'
import * as breakpoints from 'src/styles/mediaQuery'

interface RadioGroupOption {
  readonly value: string
  readonly label: string
}

const FilterOptions: RadioGroupOption[] = [
  {
    value: '',
    label: 'Coming soon',
  },
]

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  type?: 'radio'
  name: string
  value: string
  label: string
}

const RadioInput = (props: Props) => {
  const id = props.id || [props.value, props.name].join('-')
  return (
    <div
      css={(theme) => ({
        position: 'relative',
        display: 'inline-flex',
        'input + label': {
          borderColor: theme.colors.grey2,
        },
        'input:checked + label': {
          borderColor: theme.colors.grey6,
        },
      })}
    >
      <input
        type="radio"
        {...props}
        id={id}
        css={{
          position: 'absolute',
          opacity: 0,
          width: 0,
          height: 0,
        }}
      />
      <label
        htmlFor={id}
        css={(theme) => ({
          cursor: 'pointer',
          borderRadius: '100px',
          border: `2.5px solid ${theme.colors.grey2}`,
          background: '#FFF',
          boxSizing: 'border-box',
          padding: `2px 12px`,
          transition: styles.animation.selection('border-color'),
          color: theme.colors.grey6,
          ...styles.typography.smallBody,
        })}
      >
        {props.label}
      </label>
    </div>
  )
}

const RadioGroup = ({
  name,
  options,
  onInput,
}: {
  name: string
  options: RadioGroupOption[]
  onInput: () => void
}) => (
  <Flex as="fieldset" flexDirection="column">
    <Flex ml={-1} gridGap={1} flexWrap="wrap">
      {options.map(({value, label}) => (
        <Field key={value} name={name} type="radio" value={value}>
          {(props) => (
            <Box>
              <RadioInput
                {...props.input}
                type="radio"
                label={label}
                value={value}
                onInput={onInput}
              />
            </Box>
          )}
        </Field>
      ))}
    </Flex>
  </Flex>
)

const ScholarshipSearch = ({
  setSearchQuery,
  allTags,
}: {
  setSearchQuery: ({
    query,
    searchQuery,
  }: {
    query: string
    searchQuery: string
  }) => void
  allTags: string[]
}) => {
  const onSubmit = (values: {query: string; searchQuery: string}) => {
    setSearchQuery(values)
  }

  const tagsFilter = FilterOptions.concat(
    allTags?.map((item) => ({
      value: item,
      label: item,
    })) || []
  )

  const formRef = useRef<HTMLFormElement | null>(null)

  const handleRadioGroupChange = () => {
    submit()
  }

  const submit = () => {
    if (!formRef.current) {
      return
    }

    formRef.current.dispatchEvent(
      new Event('submit', {
        bubbles: true,
        cancelable: true,
      })
    )
  }
  return (
    <Box
      width={{lg: '100%', default: '100vw'}}
      ml={{default: -20, lg: '0px'}}
      background={gradients.pinkYellow}
      borderRadius={{lg: '20px', default: '0'}}
    >
      <Form onSubmit={onSubmit}>
        {({handleSubmit}) => (
          <form
            ref={formRef}
            onSubmit={handleSubmit}
            css={{flex: 1, padding: '30px'}}
          >
            <Flex
              height={{md: '2.5rem', default: '1.25rem,'}}
              alignItems="center"
            >
              <Field
                component="input"
                name="query"
                placeholder="Search by Your City, State, Identity, Area of Study & More"
                autoComplete="off"
                type="text"
                css={(theme) => ({
                  flex: 1,
                  height: '100%',
                  padding: theme.space[1],
                  borderColor: theme.colors.white,
                  borderRadius: 10,
                  ...styles.typography.body,
                  boxShadow: '0px 4px 30px 0px rgba(20, 36, 44, 0.13)',
                })}
              />
              <Action
                aria-label="Search content"
                type="submit"
                css={(theme) => ({
                  background: theme.colors.grey2,
                  padding: `${theme.space[1]} ${theme.space[2]}`,
                  minWidth: 'initial',
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                  marginLeft: '-10px',
                  boxShadow: '0px 4px 30px 0px rgba(20, 36, 44, 0.13)',
                  display: 'none',
                  [breakpoints.medium]: {
                    display: 'block',
                  },
                })}
              >
                <SearchIcon height="2.5rem" aria-label="Search" />
              </Action>
              <Action
                aria-label="Search content"
                type="submit"
                css={(theme) => ({
                  padding: `${theme.space[1]} ${theme.space[2]}`,
                  minWidth: 'initial',
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                  marginLeft: '-50px',
                  display: 'block',
                  pointerEvents: 'none',
                  [breakpoints.medium]: {
                    display: 'none',
                  },
                })}
              >
                <SearchIcon aria-label="Search" />
              </Action>
            </Flex>
            <Flex
              mt={2}
              flexWrap="wrap"
              flexDirection="row"
              alignItems="center"
              gridGap={2}
            >
              <SmallBody color="#021546">
                Choose a Pre-Recommended List:
              </SmallBody>
              <RadioGroup
                name="scholarshipTag"
                options={tagsFilter}
                onInput={handleRadioGroupChange}
              />
            </Flex>
          </form>
        )}
      </Form>
    </Box>
  )
}

export default ScholarshipSearch
