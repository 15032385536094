import {graphql} from 'gatsby'
import {ScholarshipNode, ScholarshipType} from 'gatsby-graphql-types-generated'
import React, {useState, useEffect, useRef} from 'react'
import {useLocation} from 'react-use'

import {Box, Flex} from 'src/components/Box'
import Layout from 'src/components/Layout'
import LoginModal from 'src/components/LoginModal'
import ScholarshipCarousel from 'src/components/ScholarshipCarousel'
import ScholarshipFeaturedCarousel from 'src/components/ScholarshipFeaturedCarousel'
import ScholarshipResults from 'src/components/ScholarshipResults'
import ScholarshipSearch from 'src/components/ScholarshipSearch'
import {GetSchooledIcon} from 'src/components/icons'
import {Body, HeaderXl} from 'src/components/text'
import useScholarshipSearch from 'src/hooks/useScholarshipSearch'
import useViewer from 'src/hooks/useViewer'
import * as breakpoints from 'src/styles/mediaQuery'

export type SearchResultType = {
  isLiked: boolean
  title: string
  description?: string
  requirements?: string[]
  source: string
  price?: string
  date: string
  count?: string
  applyLink: string
  featuredImageUrl?: string
}

type Props = {
  data: {
    allContentfulScholarshipTypes: {
      nodes: [ScholarshipType]
    }
  }
}

export const query = graphql`
  query ScholarshipTypes {
    allContentfulScholarshipTypes {
      nodes {
        typeName
        scholarships {
          __typename
          programName
          programSelfDescription {
            programSelfDescription
          }
          awardAmount
          numberOfAwards
          organizationName
          closeDate
          eligibilityCriteriaDescription {
            eligibilityCriteriaDescription
          }
          programUrl {
            programUrl
          }
          applicationUrl {
            applicationUrl
          }
          featuredImageUrl {
            featuredImageUrl
          }
        }
      }
    }
  }
`

type SearchQuery = {
  query?: string
  scholarshipTag?: string
}

const ScholarshipListingPage = ({data}: Props) => {
  const location = useLocation()
  const {loggedIn} = useViewer()
  const [showModal, setShowModal] = useState(false)
  const [modalCss, setModalCss] = useState({})
  const [searchQuery, setSearchQuery] = useState<SearchQuery>({})
  const [searchResults, setSearchResults] = useState<SearchResultType[] | null>(
    null
  )
  const modalRef = useRef(null)
  const allScholarships = data.allContentfulScholarshipTypes.nodes
  const featuredScholarships = allScholarships.find(
    (obj) => obj.typeName === 'Featured Scholarships'
  )
  const nonFeaturedScholarships = allScholarships.filter(
    (obj) => obj.typeName !== 'Featured Scholarships'
  )
  const searchScholarships = useScholarshipSearch()

  const handleClickOutsideModal = (event: MouseEvent) => {
    const modalElement = modalRef.current as HTMLElement | null
    const searchElement = document.getElementById(
      'scholarship-search'
    ) as HTMLElement | null

    if (
      modalElement &&
      !modalElement.contains(event.target as Node) &&
      searchElement &&
      !searchElement.contains(event.target as Node)
    ) {
      removeLoginModal()
    }
  }

  useEffect(() => {
    if (showModal) {
      document.addEventListener('click', handleClickOutsideModal)
    } else {
      document.removeEventListener('click', handleClickOutsideModal)
    }

    return () => {
      document.removeEventListener('click', handleClickOutsideModal)
    }
  }, [showModal])

  useEffect(() => {
    if (loggedIn && (searchQuery?.query || searchQuery?.scholarshipTag)) {
      const results = searchScholarships.search(
        searchQuery?.query || '',
        searchQuery?.scholarshipTag || ''
      )
      setSearchResults(
        results.map((res: ScholarshipNode) => {
          const formattedDate = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }).format(new Date(res.closeDate))
          return {
            isLiked: false,
            title: res.programName,
            description: res.programSelfDescription?.programSelfDescription,
            requirements:
              res.eligibilityCriteriaDescription?.eligibilityCriteriaDescription
                .split('-')
                .filter(Boolean)
                .map((item) => item.trim()),
            source: res.organizationName,
            price: res.awardAmount,
            date: formattedDate,
            count: res.numberOfAwards,
            applyLink: res.applicationUrl?.applicationUrl,
            featuredImageUrl: res.featuredImageUrl?.featuredImageUrl,
          }
        })
      )
    } else {
      setSearchResults(null)
    }
  }, [searchQuery, loggedIn])

  function showLoginModal() {
    setModalCss({
      pointerEvents: 'none',
      opacity: 0.3,
    })
    setShowModal(true)
  }

  function removeLoginModal() {
    setModalCss({})
    setShowModal(false)
  }

  useEffect(() => {
    if (!loggedIn) {
      const timer = setTimeout(() => {
        showLoginModal()
      }, 60000)

      return () => clearTimeout(timer)
    }
  }, [loggedIn])

  return (
    <Layout overflow="hidden">
      <Flex flexDirection="row" alignItems="center" mt={4}>
        <GetSchooledIcon
          width={{default: 38, md: 200, lg: 205}}
          height={{default: 38, md: 200, lg: 205}}
          css={{
            display: 'none',
            [breakpoints.medium]: {
              display: 'block',
            },
          }}
        />
        <Flex
          width={{lg: '75%'}}
          gridGap={2}
          flexDirection="column"
          css={{margin: '0 auto 0 auto'}}
        >
          <HeaderXl fontSize={{default: '28px', md: '2rem', lg: '2.75rem'}}>
            Scholarship Finder
          </HeaderXl>
          <Box display={{md: 'block', default: 'none'}}>
            <Body>
              Need help finding money to continue your education after high
              school? Get Schooled’s Scholarship Finder has your back! Explore
              our 16,000+ scholarship database through the search bar and
              sign-up to access even more local and national scholarships for
              college and other pathways!
            </Body>
          </Box>
        </Flex>
      </Flex>
      {featuredScholarships && (
        <ScholarshipFeaturedCarousel
          heading={featuredScholarships.typeName}
          data={featuredScholarships.scholarships}
        />
      )}
      <Box mt={{default: 3, lg: 0}} position="relative">
        <LoginModal
          showModal={showModal}
          closeModal={removeLoginModal}
          locationPathname={location.pathname || ''}
          fromModal="scholarship"
        >
          Unlock ALL
          <br />
          16,000+ Scholarships!
        </LoginModal>

        <Box
          data-modal="login-modal"
          onClick={() => {
            if (!loggedIn) {
              showLoginModal()
            }
          }}
        >
          <ScholarshipSearch
            setSearchQuery={setSearchQuery}
            allTags={searchScholarships.tags}
          />
        </Box>
        {!searchResults && (
          <Box css={{...modalCss}}>
            <Box mt={5}>
              {nonFeaturedScholarships.map((scholarship, index) => (
                <ScholarshipCarousel
                  key={index}
                  heading={scholarship.typeName}
                  isDisabled={showModal}
                  data={scholarship.scholarships}
                />
              ))}
            </Box>
          </Box>
        )}
        {searchQuery && searchResults && (
          <ScholarshipResults searchResults={searchResults} />
        )}
      </Box>
    </Layout>
  )
}

export default ScholarshipListingPage
