import {useStaticQuery, graphql} from 'gatsby'
import MiniSearch from 'minisearch'
import {ScholarshipNode} from 'gatsby-graphql-types-generated'

const useScholarshipSearch = () => {
  const data = useStaticQuery(graphql`
    query Scholarships {
      allContentfulScholarships {
        nodes {
          id
          programName
          programSelfDescription {
            programSelfDescription
          }
          awardAmount
          numberOfAwards
          organizationName
          eligibilityCriteriaDescription {
            eligibilityCriteriaDescription
          }
          programUrl {
            programUrl
          }
          applicationUrl {
            applicationUrl
          }
          closeDate
          tags
          featuredImageUrl {
            featuredImageUrl
          }
        }
      }
    }
  `)

  const scholarships = data.allContentfulScholarships.nodes

  const validObjects = scholarships.filter(
    (obj: ScholarshipNode) => obj.tags && Array.isArray(obj.tags)
  )

  const tagsArrays: string[] = validObjects.map(
    (obj: ScholarshipNode) => obj.tags
  )
  const flattenedTags: string[] = tagsArrays.flat()

  const mergedTags: string[] = [...new Set(flattenedTags)]

  const miniSearch = new MiniSearch({
    fields: [
      'programName',
      'programSelfDescription.programSelfDescription',
      'organizationName',
      'eligibilityCriteriaDescription.eligibilityCriteriaDescription',
    ],
    storeFields: Object.keys(scholarships[0]),
  })

  miniSearch.addAll(scholarships)

  function searchByTag(query: string, data: [ScholarshipNode]) {
    return data.filter((item) => item.tags?.some((tag) => tag?.includes(query)))
  }

  function searchByKeyword(searchQuery: string) {
    const results = miniSearch.search(searchQuery)
    return results
  }

  return {
    search: (searchQuery: string, filter: string = '') => {
      if (searchQuery === '' && filter === '') {
        return scholarships
      }
      let results = scholarships
      if (searchQuery !== '') {
        results = searchByKeyword(searchQuery)
      }
      if (filter !== '') {
        results = searchByTag(filter, results)
      }
      return results
    },
    tags: mergedTags,
  }
}

export default useScholarshipSearch
