import React from 'react'
import styled from '@emotion/styled'
import {space, color} from 'styled-system'

import {Box, Flex, Grid} from 'src/components/Box'
import {Header3, SmallBody} from 'src/components/text'
import {GreenYellowUnderlineV2} from 'src/components/emphasis'
import {theme} from 'src/styles'
import {
  BoxIcon,
  ClockIcon,
  DollarIcon,
  // HeartFilledIcon,
  // HeartHollowIcon,
} from 'src/components/icons'
import {ExternalButtonLink} from 'src/components/ButtonLink'
import {commaSeparateNumber, truncateString} from 'src/lib/util'

const CardContainer = styled.div`
  height: auto;
  background-color: #ffffff;
  border-radius: 4px;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0px 4px 20px 0px #14242c40;
  padding: ${theme.space[4]};
  position: relative;

  ${space}
  ${color}
`

type ScholarshipCardProps = {
  title: string
  description: string
  source: string
  price: number
  date: string
  applyLink?: string
  isLiked?: boolean
  isDisabled: boolean
  featuredImageUrl: string
}

export const ScholarshipCardDesktop = ({
  title,
  description,
  source,
  price,
  date,
  applyLink = '#',
  // isLiked,
  isDisabled,
  featuredImageUrl,
}: ScholarshipCardProps) => {
  // const [liked, setLiked] = useState<boolean>(isLiked)
  const backgroundImage = featuredImageUrl
    ? {
        backgroundRepeat: 'repeat-x',
        backgroundImage: `url("${featuredImageUrl}")`,
        height: '262px',
        backgroundSize: 'contain',
      }
    : {}
  return (
    <>
      {featuredImageUrl && (
        <a href={applyLink} target="_blank" rel="noreferrer">
          <CardContainer
            css={{width: '490px', minWidth: '490px', ...backgroundImage}}
          ></CardContainer>
        </a>
      )}
      {!featuredImageUrl && (
        <CardContainer css={{width: '490px', minWidth: '490px'}}>
          {/* Introduce it in v2
       <Box
        onClick={() => setLiked(!liked)}
        css={{
          position: 'absolute',
          right: '1.25rem',
          top: '1rem',
          cursor: 'pointer',
        }}
      >
        {liked ? <HeartHollowIcon /> : <HeartFilledIcon />}
      </Box> */}
          <Flex
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            gridGap="2"
          >
            <Header3 fontSize={24} textAlign="center" mb={3}>
              <GreenYellowUnderlineV2>
                {truncateString(title, 50, '')}
              </GreenYellowUnderlineV2>
            </Header3>
            <Flex flexDirection="row" gridGap="2" alignItems="center">
              <Flex flexDirection="column" gridGap="2">
                <Grid
                  gridRowGap="2"
                  gridTemplateColumns={{_: 'auto auto'}}
                  gridTemplateRows={{_: 'auto auto'}}
                >
                  <DollarIcon mr={1} mt="5px" height={17} />
                  <Box>
                    {price && (
                      <SmallBody fontSize={16} fontWeight="700">
                        ${commaSeparateNumber(price)}
                      </SmallBody>
                    )}
                    {!price && (
                      <SmallBody fontSize={16} fontWeight="700">
                        See website
                      </SmallBody>
                    )}
                  </Box>
                  <ClockIcon mr={1} mt="4px" />
                  <SmallBody
                    fontSize={16}
                    css={{
                      whiteSpace: 'nowrap',
                    }}
                    fontWeight="700"
                  >
                    {date}
                  </SmallBody>
                  <>
                    <BoxIcon mr={1} height={16} mt="5px" />
                    {source && (
                      <SmallBody fontSize={16} fontWeight="700">
                        {truncateString(source, 55, '')}
                      </SmallBody>
                    )}
                    {!source && (
                      <SmallBody fontSize={16} fontWeight="700">
                        See website
                      </SmallBody>
                    )}
                  </>
                </Grid>
              </Flex>
              <Box>
                <SmallBody>
                  {(description && truncateString(description)) ||
                    'For more details about this scholarship, check out the scholarship page.'}
                </SmallBody>
              </Box>
            </Flex>
            <ExternalButtonLink
              target="_blank"
              variant={isDisabled ? 'secondary' : 'primary'}
              href={applyLink}
              css={{
                fontWeight: 1000,
                width: '100%',
                maxWidth: '10rem',
              }}
            >
              Apply Now
            </ExternalButtonLink>
          </Flex>
        </CardContainer>
      )}
    </>
  )
}
