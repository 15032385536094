import React from 'react'
import styled from '@emotion/styled'

import {Box, Flex, Grid} from 'src/components/Box'
import {ExtraSmallBody, Header4} from 'src/components/text'
import {GreenYellowUnderlineV2} from 'src/components/emphasis'
import {BoxIcon, ClockIcon, DollarIcon} from 'src/components/icons'
import {ExternalButtonLink} from 'src/components/ButtonLink'
import {commaSeparateNumber, truncateString} from 'src/lib/util'

const CardContainer = styled.div`
  height: auto;
  background-color: #ffffff;
  width: 290px;
  height: 130px;
  position: relative;
`

type ScholarshipCardProps = {
  title: string
  source: string
  price: number
  date: string
  applyLink?: string
  featuredImageUrl?: string
}

export const MobileFeatured = ({
  title,
  price,
  source,
  date,
  applyLink = '#',
  featuredImageUrl,
}: ScholarshipCardProps) => {
  const backgroundImage = featuredImageUrl
    ? {
        backgroundRepeat: 'repeat',
        backgroundImage: `url("${featuredImageUrl}")`,
        height: '100%',
        backgroundSize: 'contain',
      }
    : {}
  return (
    <>
      {featuredImageUrl && (
        <a href={applyLink} target="_blank" rel="noreferrer">
          <CardContainer
            css={{
              ...backgroundImage,
            }}
          ></CardContainer>
        </a>
      )}
      {!featuredImageUrl && (
        <CardContainer>
          <Flex
            m="15px"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Header4 textAlign="center" fontSize={15} mb={3}>
              <GreenYellowUnderlineV2>
                {truncateString(title, 50, '')}
              </GreenYellowUnderlineV2>
            </Header4>
            <Flex flexDirection="row" gridGap={2}>
              <Box flexBasis={800}>
                <Grid
                  gridRowGap={1}
                  gridTemplateColumns={{_: '1fr 6fr'}}
                  gridTemplateRows={{_: 'auto auto'}}
                >
                  <>
                    <DollarIcon height={10} mt={'2px'} />
                    <Box>
                      {price && (
                        <ExtraSmallBody fontSize={8}>
                          ${commaSeparateNumber(price)}
                        </ExtraSmallBody>
                      )}
                      {!price && (
                        <ExtraSmallBody fontSize={8}>
                          See website
                        </ExtraSmallBody>
                      )}
                    </Box>
                  </>
                  <ClockIcon height={10} mt={'2px'} />
                  {date && <ExtraSmallBody fontSize={8}>{date}</ExtraSmallBody>}
                  {!date && (
                    <ExtraSmallBody fontSize={8}>See website</ExtraSmallBody>
                  )}
                  <BoxIcon height={10} mt={'2px'} />
                  {source && (
                    <ExtraSmallBody fontSize={8}>
                      {truncateString(source, 50, '')}
                    </ExtraSmallBody>
                  )}
                  {!source && (
                    <ExtraSmallBody fontSize={8}>See website</ExtraSmallBody>
                  )}
                </Grid>
              </Box>
              <ExternalButtonLink
                target="_blank"
                href={applyLink}
                css={{
                  fontWeight: 900,
                  alignSelf: 'center',
                  minHeight: '35px',
                  minWidth: 130,
                  height: '35px',
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                <ExtraSmallBody fontWeight={900}>Apply Now</ExtraSmallBody>
              </ExternalButtonLink>
            </Flex>
          </Flex>
        </CardContainer>
      )}
    </>
  )
}

export default MobileFeatured
